@use "../global" as global;


.c-payment-card {
  background-color: global.$white;
  width: 100%;
  margin: calc(global.$base / 2);
  box-sizing: border-box;
  border: 1px solid global.$black;
  border-radius: global.$border-radius;
  color: global.$black;
  text-decoration: none;
  padding: calc(global.$base / 2);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: global.$sm-break) {
    flex-direction: column;
  }

  // &:hover {
  //   outline: solid 1px global.$primary;
  //   color: global.$black;
  // }

  img {
    width: 30%;
    object-fit: contain;

    @media (max-width: global.$sm-break) {
      width: 100%;
    }
  }

  &__layout {
    display: flex;
    flex-direction: column;
    width: 85%;
  }

  &__children {
    margin: global.$base;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    text-align: left;
  }

  &__name {
    width: 100%;
    text-align: left;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin: global.$base;
    padding: calc(global.$base / 2);
    cursor: pointer;
    text-decoration: none;
    color: global.$black;

    @media (max-width: global.$md-break) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &:hover {
      text-decoration: underline;
      color: global.$black;
    }
  }

  &__price {
    color: global.$primary;
    font-weight: 700;
    font-size: global.$font-size-md;
    text-align: right;
  }

  &.-secondary {
    background-color: global.$black;
    color: global.$white;

    // &:hover {
    //   outline: solid 2px global.$primary;
    // }

  }
}