@use "../../global" as global;

.product-preview {
  text-align: left;
  margin: 0 auto;
  max-width: global.$lg-break;
  position: relative;
  padding-top: global.$base;
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);

  @media (max-width: global.$lg-break) {
    margin: 0 global.$base;
  }

  &__product-info {
    background-color: global.$accent-1;
    border-radius: global.$border-radius;
    padding: calc(global.$base * 2);
  }

  &__actions {
    position: absolute;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__add-cart-btn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  &__title {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem 0 1rem 0;

    @media (max-width: global.$md-break) {
      flex-direction: column;
      margin: 6rem 0 1rem 0;
    }
  }

  &__description {
    margin: 1rem 0;
  }

  &__price {
    color: global.$black;
    font-weight: 700;
  }

  &__preview {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h3 {
      font-weight: 700;
    }

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc(global.$base * 4);
  }
  
  &__delete-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin-top: calc(global.$base * 2);
  }
}

