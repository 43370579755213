@use "../global" as global;

.c-loader {
  &.-inline {
    display: inline;
  }

  &.-block {
    display: block;
  }
}
