@use "../../global" as global;

.sell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);

  .seller-dashboard {
    max-width: global.$lg-break;
    color: global.$black;
    width: 100%;
    margin: 0 auto;
    padding-top: global.$base;
    padding: 1rem 1rem 0 1rem;

    @media (max-width: global.$md-break) {
      width: 90%;
    }

    .c-payment-card {
      width: auto;

      img {
        width: 10%;
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: calc(global.$base * 2);

      @media (max-width: global.$md-break) {
        flex-direction: column;
        justify-content: space-around;
      }

      &__balance {
        display: flex;
        flex-direction: row;
        width: auto;
        text-align: center;

        @media (max-width: global.$md-break) {
          width: 100%;
          justify-content: space-around;
          // flex-direction: column-reverse;
        }
      }

      &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 0.2rem 0.5rem;
      }
    }

    &__list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .start-selling {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3,
    h4 {
      text-align: center;
    }

    &__header {
      width: 100%;
      background-color: global.$primary;
      color: global.$white;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5rem 0rem;

      h2 {
        text-align: center;
        color: global.$white;
      }
    }
  }

  .store-form {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: global.$base;

    h2 {
      text-align: center;
    }

    &__btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &__submitted {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
}

.seller-dashboard {
  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: global.$font-size-h2;
    }
  }

  &__buttons {
    margin-top: calc(global.$base * 2);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-right: global.$base;

    @media (max-width: global.$sm-break) {
      margin: 0;

      button {
        &:first-child {
          margin-bottom: global.$base;
        }
      }
    }
  }
}
