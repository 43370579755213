/* Colors */
$primary-1: #040666;
$secondary-1: #ff8900;
$secondary-2: #ffb700;
$accent-1: #fff;
$accent-2: #040666;
$dark-1: #000000;
$light-1: #ffffff;

//vox storia
$primary: #040666;//#5e2d67;
$secondary: #cfc0e3;
$accent-1: #e3e3e3;
$accent-2: #040666; //#ffe347;
$background: #e3e3e3;

$white: #fff;
$black: #292929;
$error: #a61a2f;
$success: #00B000;

// Grayscale
$gray-98: #fafafa;
$gray-97: #f7f7f7;
$gray-93: #ededed;
$gray-89: #e2e2e2;
$gray-87: #dedede;
$gray-78: #c7c6c6;
$gray-76: #c1c1c2;
$gray-58: #949494;
$gray-56: #8e8e8e;
$gray-55: #737373;
$gray-20: #333333;