@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat/Montserrat-Regular.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat/Montserrat-Light.otf) format("opentype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(fonts/Montserrat/Montserrat-SemiBold.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Wolvercote";
  src: local("Wolvercote"),
    url(fonts/Wolvercote/Wolvercote-Regular.otf) format("opentype");
}

@font-face {
  font-family: "PressStart2P";
  src: local("PressStart2P"),
    url(fonts/PressStart2P/PressStart2P.ttf) format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
