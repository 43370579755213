@use "../../global/" as global;

.avatar-form {
  background-color: global.$white;
  padding: global.$base;
  border-radius: global.$border-radius;

  // Table
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    img {
      width: 100%;
      object-fit: contain;
    }

    th,
    td {
      border: solid 1px global.$black;
      padding: calc(global.$base / 2);
      box-sizing: border-box;
    }
  }

  hr {
    border: solid calc(global.$base / 12) global.$black;
    background-color: transparent;
    margin: global.$base 0;
  }

  h5 {
    color: global.$secondary-1;
    font-weight: bold;
  }

  &__images {
    display: flex;
    flex-direction: row;
  }

  &__image {
    width: 25%;
    padding: global.$base;
    box-sizing: border-box;

    @media (max-width: global.$md-break) {
      width: 50%;
    }

    &--full {
      width: 100%;
    }

    img {
      width: 100%;
      object-fit: contain;
      background-color: global.$gray-55;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 calc(global.$base) !important;
    }
  }

  &__list-image {
    width: 50%;
    margin: 0 auto;
  }

  &__preview {
    position: relative;
  }

  &__point {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: global.$border-radius;
    position: absolute;

    &--small {
      width: 5px;
      height: 5px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
  }

  &__half-width {
    width: 50%;
    padding: global.$base;
    box-sizing: border-box;
  }

  &__buttons {
    display: flex;

    &--right {
      margin-left: auto;
    }

    &--vertical {
      margin: calc(global.$base / 2) 0;
    }

    .MuiButton-root {
      margin: 0 calc(global.$base / 4);
    }
  }

  // Other
  &__form {
    display: flex;
    flex-direction: column;

    .MuiTextField-root,
    .MuiFormControlLabel-root {
      margin-bottom: calc(global.$base / 2);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    margin: calc(global.$base / 2) 0;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  // Modifiers
  &--clickable {
    cursor: pointer;
    color: global.$secondary-1;

    &:hover {
      text-decoration: underline;
    }
  }

  &--delete {
    cursor: pointer;
    color: red;

    &:hover {
      text-decoration: underline;
    }
  }

  &--grow {
    flex: 1;
  }

  &--row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &--border {
    border: solid 1px global.$black;
  }

  //new styling for new avatar form
  &__new-img {
    width: 100%;
  }

  &__new-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: global.$md-break) {
      flex-direction: column;
      padding-bottom: global.$base;
    }
  }

  &__image-upload {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: global.$md-break) {
      flex-direction: column;
      padding-bottom: global.$base * 3;
    }
  }

  &__new-save-preview {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: global.$base * 2;

    @media (max-width: global.$md-break) {
      flex-direction: column;
    }
  }
}
