@use "../global" as global;

// Container
$container-width: global.$base * 62.5;
$container-sm-width: global.$base * 31.25;
$container-md-width: $container-width;
$container-lg-width: global.$base * 78.125;

// React Select Component
$select-background-color: global.$white;//global.$accent-1;

$default-font-family: global.$montserrat;
$default-font-size: global.$base;
$default-font-color: global.$black;

$dropdown-placeholder-color: global.$gray-58;
$indicator-color: global.$primary;
$dropdown-hover-color: global.$primary;
$focus-color: global.$primary;

$menu-background-color: global.$white;
$menu-focus-color: global.$primary;
$menu-selected-color: lighten(global.$primary, 15%);

$no-option-color: global.$error;

$select-secondary-background-color: rgba(255, 255, 255, 1);
$secondary-font-color: global.$black;
$secondary-description-color: global.$black;

$dropdown-hover-border: 2px solid $dropdown-hover-color;
$dropdown-focus-border: 2px solid $focus-color !important;

$dropdown-disabled-color: global.$gray-58;

$multi-value-background-color: global.$white;
$multi-value-remove-color: global.$primary;
$multi-value-remove-x-color: global.$white;

$dropdown-indicator-color: global.$gray-58;
$clear-indicator-color: global.$gray-58;

// Description
$description-size: $default-font-size;
$description-color: global.$white;

// Error
$error-size: global.$base * 1.875;
$dropdown-error-color: global.$error;
$dropdown-error-border: 2px solid global.$error;

$dropdown-option-color: global.$black;

.c-dropdown-container {
  width: $container-width;

  &.w-sm {
    width: $container-sm-width;
  }

  &.w-md {
    width: $container-md-width;
  }

  &.w-lg {
    width: $container-lg-width;
  }

  &.w-responsive {
    width: 100%;
  }

  &.has-error {
    .c-dropdown {
      &__control {
        border: $dropdown-error-border;

        &:hover {
          border: $dropdown-error-border;
        }

        &--is-focused {
          border: $dropdown-error-border !important;
        }
      }
    }
  }

  .c-dropdown {
    &__control {
      background-color: $select-background-color;
      border: 2px solid global.$primary;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        border: $dropdown-hover-border;
      }

      &--is-focused {
        border: $dropdown-focus-border;
      }

      &--is-disabled {
        background-color: $dropdown-disabled-color;
      }
    }

    &__value-container {
      font-family: $default-font-family;
      font-size: $default-font-size;
      color: $default-font-color;
    }

    &__placeholder {
      color: $dropdown-placeholder-color;
    }

    &__input {
      color: $default-font-color;
    }

    &__indicators {
      .c-dropdown__indicator {
        color: $indicator-color;

        &:hover {
          color: $dropdown-indicator-color;
        }
      }

      .c-dropdown__indicator-separator {
        background-color: $indicator-color;
      }

      .c-dropdown__clear-indicator {
        &:hover {
          color: $clear-indicator-color;
        }
      }
    }

    &__value-container {
      .c-dropdown__single-value {
        color: $default-font-color;
      }
    }

    &__menu {
      &-list {
        background-color: $menu-background-color;

        .c-dropdown__option {
          cursor: pointer;
          font-size: $default-font-size;
          font-family: $default-font-family;
          color: $dropdown-option-color;

          &--is-focused {
            background-color: $menu-focus-color;
            color: global.$secondary;
          }

          &--is-selected {
            background-color: $menu-selected-color;
            color: global.$white;
          }
        }
      }

      &-notice {
        color: $no-option-color;
      }
    }

    &__description {
      color: $description-color;
      font-size: $description-size;
      font-family: $default-font-family;
    }

    &__error-message {
      color: $dropdown-error-color;
      font-size: $error-size;
      font-family: $default-font-family;
    }

    &__multi-value {
      background-color: $multi-value-background-color;

      &__remove:hover {
        background-color: $multi-value-remove-color;

        svg {
          color: $multi-value-remove-x-color;
        }
      }
    }
  }

  &.c-dropdown__secondary {
    & .c-dropdown {
      &__control {
        background-color: $select-secondary-background-color;

        &--is-disabled {
          background-color: $dropdown-disabled-color;
        }
      }

      &__input {
        color: $secondary-font-color;
      }

      &__value-container {
        color: $secondary-font-color;

        .c-dropdown__single-value {
          color: $secondary-font-color;
        }
      }

      &__description {
        color: $secondary-description-color;
      }
    }
  }

  &.c-dropdown__tertiary {
    & .c-dropdown {
      &__control {
        background-color: global.$secondary;

        &--is-disabled {
          background-color: $dropdown-disabled-color;
        }
      }

      &__input {
        color: $secondary-font-color;
      }

      &__value-container {
        color: $secondary-font-color;

        .c-dropdown__single-value {
          color: global.$white;
        }
      }

      &__description {
        color: $secondary-description-color;
      }

      &__menu {
        &-list {
          background-color: global.$secondary;
        }
      }

      &__option {
        color: global.$white;
      }
    }
  }
}
