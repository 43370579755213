// @use "../global/variables";
@use "../global" as global;

.logo {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: global.$light-1;

  &__NE {
    width: 40%;
    padding: global.$base 0;

    @media screen and (max-width: global.$sm-break) {
      width: 75%;
    }
  }

  &__image {
    width: calc(global.$base * 8);
    // height: 25vh;

    @media screen and (max-width: global.$sm-break) {
      max-width: 128px;
      height: auto;
    }
  }

  &__subtitle {
    text-align: center;
    width: 25%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: global.$secondary-2;
    font-family: "Wolvercote";

    @media screen and (max-width: global.$xl-break) {
      width: 45%;

      @media screen and (max-width: global.$lg-break) {
        width: 60%;

        @media screen and (max-width: global.$md-break) {
          width: 95%;
        }
      }
    }

    span {
      font-size: calc(global.$base * 3);

      @media screen and (max-width: global.$xl-break) {
        font-size: calc(global.$base * 2);

        @media screen and (max-width: global.$lg-break) {
          font-size: calc(global.$base * 2);

          @media screen and (max-width: global.$md-break) {
            font-size: calc(global.$base * 2);
          }
        }
      }
    }
  }

  &__header {
    width: 25%;
    color: global.$secondary-2;
    font-family: "PressStart2P";

    @media (max-width: global.$lg-break) {
      width: 30%;

      @media (max-width: global.$lg-break) {
        width: 40%;

        @media (max-width: global.$md-break) {
          width: 50%;
        }
      }
    }
  }
}

.logo__stacked {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.bottom-logo {
  max-width: 20rem;
  text-align: center;

  p {
    color: global.$secondary-1;
    font-family: "PressStart2P";
  }

  img {
    width: calc(global.$base * 8);
  }
}