@use "../global/index" as global;
@use "sass:math";

$search-border-radus: global.$border-radius;
$search-border-color: global.$black;
$search-background-color: global.$white;
$search-border-width: 1px;
$search-clear-btn-margin: 44px;

.c-searchbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: $search-background-color;
  border-radius: $search-border-radus;
  border: solid 1px global.$black;

  &__group {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: $search-border-radus;
    background-color: global.$accent-1;
  }

  &__input {
    border-width: $search-border-width;
    border: none;
    border-color: $search-border-color;
    border-right: 0;
    box-shadow: none;
    background: transparent;
    flex: 1;
    color: $search-border-color;
    display: block;
    font-family: inherit;
    font-size: global.$base;
    height: 25px;
    outline: 3px solid transparent;
    min-width: 0;
    padding: (global.$base * 1.2) 0;
    padding-left: global.$base;
  }

  &__search-btn {
    border-width: $search-border-width;
    border-color: $search-border-color !important;
    transition: visibility 0ms;
    background: none;
    cursor: pointer;
    border-left: 0;
    z-index: 10;
  }

  &__clear-btn {
    padding: 1px;
    cursor: pointer;
    outline: none;
    transition-duration: 0.5s;

    &--visible {
      opacity: 1;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      border-radius: $search-border-radus;
      background-color: global.$gray-87;
    }
  }

  &__search-btn,
  &__clear-btn {
    // padding: global.$base;

    &:hover,
    &:focus {
      background-color: global.$gray-87;
      border-radius: $search-border-radus;
      svg {
        fill: global.$primary;
      }
    }
  }
}
