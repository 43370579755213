@use "../../global" as global;

.purchase-history {
  // max-width: global.$lg-break;
  // margin: calc(global.$base * 14) auto;
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);

  &__purchases {
    width: 100%;
  }

  &__purchase {
    max-width: global.$lg-break;
    margin: 0 auto;
  }

  .c-payment-card {
    margin: 0;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: global.$md-break) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .c-payment-card {
    img {
      width: 15%;

      @media (max-width: global.$sm-break) {
        width: 100%;
      }
    }
  }

  &__product {
    border: solid 1px global.$black;
    background-color: global.$white;
    border-radius: global.$border-radius;
    margin-bottom: calc(global.$base * 2);

    .c-payment-card {
      border: transparent;

      hr {
        margin: 0;
      }

      &__title {
        margin: 0;
      }
    }
  }

  &__product-payment {
    color: darken(global.$secondary, 40%);
    padding: calc(global.$base / 2) global.$base;
    border-bottom: solid 1px global.$black;
    box-sizing: border-box;
  }

  &__title {
    margin-bottom: global.$base;
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }

  &__payment-completed-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
