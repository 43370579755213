@use "../../global/index" as global;

.faq {
  max-width: global.$lg-break;
  margin: 0 auto;
  background-color: global.$primary-1;

  &__title {
    font-size: global.$font-size-h6;
  }

  &__container {
    background-color: darken(global.$white, 10%);
    padding: calc(global.$base * 2);
    border-radius: global.$border-radius;
    margin-bottom: calc(global.$base * 2);
  }

  &__accordion {
    margin-top: global.$base;
  }
}
