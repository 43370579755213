@use '../global/' as global;

.cookies {
  position: fixed;
  background-color: white;
  width: 100vw;
  bottom: 0;
  text-align: center;
  overflow-y: hidden;

  &--expanded {
    overflow-y: scroll;
  }

  p {
    margin: 0;
    text-align: left;
  }

  &__disclaimer {
    padding: global.$base 0;

    p {
      text-align: center;
    }
  }

  &__preferences {
    max-height: 0px;
    transition-duration: .3s;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--expanded {
      max-height: 200px;
    }

  }

  &__general,
  &__info {
    width: 60%;
  }

  &__general {
    margin-bottom: global.$base;

    p {
      text-align: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    margin-bottom: global.$base;

    &:last-child {
      padding-bottom: calc(global.$base * 2);
    }
  }

  &__checkbox {
    min-width: 120px;
    max-width: 120px;
    text-align: left;
  }
}