@use "../global" as global;

$pagination_font-size: global.$font-size-body;
$pagination_font-family: global.$montserrat;
$pagination_font-weight: 700;

$pagination_border-radius: global.$border-radius * 4;
$pagination_border-width: global.$border-width;

$pagination_primary-text-color: global.$black;
$pagination_primary-btn-color-active: global.$accent-1;
$pagination_primary-text-color-active: global.$black;
$pagination_primary-background-color: global.$gray-78;

$pagination_secondary-text-color: global.$white;
$pagination_secondary-btn-color-active: global.$primary;
$pagination_secondary-text-color-active: global.$white;
$pagination_secondary-background-color: global.$gray-55;

$pagination_hover-background-color: global.$accent-1;
$pagination_secondary-hover-background-color: global.$primary;

$pagination_height-item: global.$base * 3;
$pagination_width-item: global.$base * 3;
$pagination_spacing-item: global.$base;

.c-pagination {
  align-items: center;
  display: flex;
  color: $pagination_primary-text-color;

  &.-secondary {
    color: $pagination_secondary-text-color;
  }

  &__list {
    align-items: center;
    display: flex;
  }

  &__item {
    align-items: center;
    display: flex;
    font-size: $pagination_font-size;
    font-family: $pagination_font-family;
    font-weight: $pagination_font-weight;
    justify-content: center;
    margin: 0;
    text-align: center;
    height: $pagination_height-item;
    width: $pagination_width-item;
    border-radius: $pagination_border-radius;
    border-width: $pagination_border-width;
    background-color: $pagination_primary-background-color;
    padding: 4px;

    button {
      font-weight: $pagination_font-weight;
    }

    & + & {
      margin-left: $pagination_spacing-item;
    }

    &.-gap {
      border-width: 0;
      background-color: transparent;
      width: auto;
    }

    &.-secondary {
      background-color: $pagination_secondary-background-color;
    }

    &:not(.-gap) {
      &:hover {
        background-color: $pagination_hover-background-color;
      }

      &.-secondary {
        &:hover {
          background-color: $pagination_secondary-hover-background-color;
        }
      }

      &.is-active {
        text-decoration: underline;
        background-color: $pagination_primary-btn-color-active;

        &.-secondary {
          background-color: $pagination_secondary-btn-color-active;
        }
      }
    }

    &.-arrow {
      background-color: $pagination_primary-background-color;

      &:hover {
        background-color: $pagination_hover-background-color;
      }

      &.-secondary {
        background-color: $pagination_secondary-background-color;

        &:hover {
          background-color: $pagination_secondary-hover-background-color;
        }
      }

      &.is-disabled {
        background-color: global.$gray-55;

        &.-secondary {
          background-color: global.$gray-93;
        }

        * {
          cursor: not-allowed;
        }
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    color: $pagination_primary-text-color;
    font-size: $pagination_font-size;
    font-family: $pagination_font-family;
    font-weight: $pagination_font-weight;

    &.is-active {
      color: $pagination_primary-text-color-active;

      &.-secondary {
        color: $pagination_secondary-text-color-active;
      }
    }

    &.-secondary {
      color: $pagination_secondary-text-color;
    }
  }

  &.-secondary {
    color: $pagination_secondary-text-color;
    // background-color: $pagination_secondary-background-color;
  }
}
