@use "../../global/index" as global;

.cart {
  text-align: left;
  margin: 0 auto;
  max-width: global.$lg-break;
  position: relative;
  // padding-top: global.$base;
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);

  @media (max-width: global.$lg-break) {
    margin: 0 global.$base;
  }

  &__title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: global.$md-break) {
      flex-direction: column-reverse;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 75%;
    flex-grow: 1;
    flex-basis: 75%;

    @media (max-width: global.$md-break) {
      width: 100%;
      max-width: 100%;
      flex-basis: auto;
    }
  }

  .full-list {
    max-width: 100%;
    flex-basis: 100%;
  }

  &__checkout-section {
    box-shadow: 0 4px 20px rgba(22, 22, 22, 0.15);
    border-radius: global.$border-radius;
    margin-top: 0.5rem;
    padding: global.$base * 2;
    overflow: hidden;
    background-color: global.$white;
    max-width: 20%;
    flex-grow: 1;
    flex-basis: 20%;
    align-items: center;
    justify-content: center;

    @media (max-width: global.$md-break) {
      width: 90%;
      max-width: 100%;
      flex-basis: auto;
    }

    button {
      margin-top: 1rem;
      width: 100%;
    }
  }

  &__checkout-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__list-item > * {
    margin: 1rem 0;
  }

  &__no-items {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__payment-checkout {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @media (max-width: global.$md-break) {
      flex-direction: column;
    }
  }

  &__payment-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 75%;
    flex-grow: 1;
    flex-basis: 75%;

    @media (max-width: global.$md-break) {
      width: 100%;
      max-width: 100%;
      flex-basis: auto;
    }
  }

  &__errorMsg {
    width: 100%;
    display: flex;
    color: global.$error;
    justify-content: center;
    align-items: center;
    padding: global.$base;
    border: solid 2px;
    border-radius: global.$border-radius;
    background-color: rgba(166, 26, 47, 0.1);
  }
}