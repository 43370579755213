@use "../../global/" as global;

.add-npc {
  background-color: global.$accent-1;
  padding: global.$base;
  border-radius: global.$border-radius;

  &__whitebg {
    max-width: global.$lg-break;
    margin: 0 auto;
  }

  &__flexrow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: global.$md-break) {
      flex-direction: column;
      align-items: inherit;
    }
  }

  &__container {
    max-width: global.$lg-break;
    margin: 0 auto;
  }

  &__field-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: global.$base;
  }

  &__description {
    margin-bottom: global.$base !important;
  }

  &__product-title {
    width: 75%;
  }

  .c-money-input {
    width: 23%;

    .MuiFormControl-root {
      width: 100%;
    }
  }

  &__cropper-wrapper {
    position: relative;
    margin-bottom: global.$base;
  }

  &__cropper-container {
    position: relative;
    height: calc(global.$base * 20);
    margin-top: global.$base;
  }

  &__buttons {
    display: flex;
    margin-bottom: global.$base;
    justify-content: center;

    button {
      &:first-child {
        margin-right: global.$base;
      }
    }
  }

  &__existing-avatar {
    max-width: global.$lg-break;
    margin: 0 auto;
  }

  &__thumbnail-image {
    display: flex;
    flex-direction: column;
  }
}
