@use "../global" as global;

$tooltip-content-bg: global.$primary;
$tooltip-content-color: global.$white;
$tooltip-content-border-radius: global.$border-radius;
$tooltip-content-padding: global.$base;

$tooltip-wrapper-width-sm: global.$base * 15.625; // sm
$tooltip-wrapper-width-md: global.$base * 31.25; // md
$tooltip-wrapper-width-lg: global.$base * 46.875; // lg

$tooltip-content-marin-right: global.$base;

$tooltip-primary-arrow-color: global.$primary;
$tooltip-secondary-arrow-color: global.$black;
$tooltip-light-arrow-color: global.$accent-1;
$tooltip-dark-arrow-color: global.$primary;

$tooltip-secondary-content-color: global.$white;
$tooltip-light-content-color: global.$black;

.c-tooltip {
  position: relative;
  display: inline-flex;

  &__wrapper {
    position: absolute;
    opacity: 1;
    margin: 0 global.$base;
  }

  &__content {
    text-align: center;
    justify-content: center;
    align-items: center;

    background-color: $tooltip-content-bg;
    color: $tooltip-content-color;
    border-radius: $tooltip-content-border-radius;
    padding: $tooltip-content-padding;
  }

  &__ws {
    &-sm {
      .c-tooltip__wrapper {
        width: $tooltip-wrapper-width-sm; // sm
      }
    }

    &-md {
      .c-tooltip__wrapper {
        width: $tooltip-wrapper-width-md; // md
      }
    }

    &-lg {
      .c-tooltip__wrapper {
        width: $tooltip-wrapper-width-lg; // lg
      }
    }
  }

  &__right {
    left: 100%;

    height: 100%;
    display: flex;
    align-items: center;

    text-align: right;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: global.$base solid transparent;
      border-bottom: global.$base solid transparent;

      border-right: global.$base solid $tooltip-primary-arrow-color;
    }
  }

  &__left {
    right: 100%;

    height: 100%;
    display: flex;
    align-items: center;

    justify-content: flex-end;

    .c-tooltip__content {
      margin-right: $tooltip-content-marin-right;
    }

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: global.$base solid transparent;
      border-bottom: global.$base solid transparent;

      border-left: global.$base solid $tooltip-primary-arrow-color;

      position: absolute;
      margin-left: 100%;
    }
  }

  &__top {
    bottom: 100%;

    width: 100% !important;
    display: flex;
    justify-content: center;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: global.$base solid transparent;
      border-right: global.$base solid transparent;

      border-top: global.$base solid $tooltip-primary-arrow-color;

      position: absolute;
      bottom: -0.4rem;
      margin: 0 auto;
    }
  }

  &__bottom {
    top: 100%;

    width: 100% !important;
    display: flex;
    justify-content: center;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: global.$base solid transparent;
      border-right: global.$base solid transparent;

      border-bottom: global.$base solid $tooltip-primary-arrow-color;

      position: absolute;
      top: -0.4rem;
      margin: 0 auto;
    }
  }

  &__secondary {
    .c-tooltip {
      &__content {
        color: $tooltip-secondary-content-color;
        background-color: $tooltip-secondary-arrow-color;
      }

      &__right {
        &::before {
          border-right: global.$base solid $tooltip-secondary-arrow-color;
        }
      }

      &__left {
        &::before {
          border-left: global.$base solid $tooltip-secondary-arrow-color;
        }
      }

      &__top {
        &::before {
          border-top: global.$base solid $tooltip-secondary-arrow-color;
        }
      }

      &__bottom {
        &::before {
          border-bottom: global.$base solid $tooltip-secondary-arrow-color;
        }
      }
    }
  }

  &__light {
    .c-tooltip {
      &__content {
        background-color: $tooltip-light-arrow-color;
        color: $tooltip-light-content-color;
      }

      &__right {
        &::before {
          border-right: global.$base solid $tooltip-light-arrow-color;
          outline: 1px solid black;
        }
      }

      &__left {
        &::before {
          border-left: global.$base solid $tooltip-light-arrow-color;
        }
      }

      &__top {
        &::before {
          border-top: global.$base solid $tooltip-light-arrow-color;
        }
      }

      &__bottom {
        &::before {
          border-bottom: global.$base solid $tooltip-light-arrow-color;
        }
      }
    }
  }

  // &__dark {
  //   .c-tooltip {
  //     &__content {
  //       background-color: $tooltip-dark-arrow-color;
  //     }

  //     &__right {
  //       &::before {
  //         border-right: global.$base solid $tooltip-dark-arrow-color;
  //       }
  //     }

  //     &__left {
  //       &::before {
  //         border-left: global.$base solid $tooltip-dark-arrow-color;
  //       }
  //     }

  //     &__top {
  //       &::before {
  //         border-top: global.$base solid $tooltip-dark-arrow-color;
  //       }
  //     }

  //     &__bottom {
  //       &::before {
  //         border-bottom: global.$base solid $tooltip-dark-arrow-color;
  //       }
  //     }
  //   }
  // }
}
