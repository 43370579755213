@use "../../global/" as global;


.navigation {
  position: absolute;
  top: 0;

  .topbar {
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1000002;
    width: 100%;
    background: white;
    outline: solid 2px global.$primary;
    padding: 0.4rem;
    height: 3.4rem; //Note: if this changes, change private route styling
    
    &__spacing {
      width: 100%;
      max-width: global.$lg-break;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    
    &__logo {
      display: flex;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    &__title {
      margin-left: calc(global.$base / 2);
      font-family: Wolvercote;
      font-size: global.$font-size-h4;
      transition: .1s;
      color: global.$black;

      &:hover {
        color: global.$secondary-1;
      }
    }
  }

  .nav-menu {
    margin-right: calc(global.$base * 3);
    min-width: 15rem;
    max-width: calc(global.$base * 22);
    position: fixed;
    right: 0;
    z-index: 1000000;
    background-color: white;
    color: black;
    border-radius: 0 0 global.$border-radius global.$border-radius;
    transition-duration: 0.5s;

    &--open {
      transform: translateY(3.5rem);
    }

    &--closed {
      transform: translateY(-40rem);
    }

    &__list {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 7px 10px rgb(0 0 0 / 20%);

      &-item {
        user-select: none;
        padding: global.$base calc(global.$base * 2);
        text-decoration: none;
        font-size: global.$font-size-md;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: global.$black;
        text-align: start;

        &:hover {
          background-color: global.$primary;
          color: white;

          &:last-child {
            border-radius: 0 0 global.$border-radius global.$border-radius;
          }
        }
      }
    }

    &__welcome {
      font-size: global.$font-size-xl;
      color: black;
      font-weight: 600;

      &:hover {
        background-color: transparent;
        color: black;
      }
    }

    &__logout {
      color: red;
      margin-top: calc(global.$base * 2);

      &:hover {
        background: red;
        color: white;
      }
    }
  }
}


// .navigation {
//   .image-wrapper {
//     text-align: center;
//     margin: calc(global.$base * 2) auto;

//     img {
//       width: 100%;
//     }
//   }

//   .navbar {
//     background-color: global.$primary-1 !important;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     background-color: global.$accent-1;
//     border-radius: global.$border-radius;
//     padding: calc(global.$base / 2) global.$base;

//     @media (max-width: global.$sm-break) {
//       flex-direction: column;
//     }

//     button {
//       margin: 0 global.$base;
//     }
//   }

//   .logo {
//     display: flex;
//     flex-direction: row;

//     .logo__image {
//       width: auto;
//       min-height: 40px;
//       height: 10vh;
//     }

//     .logo__NE {
//       width: auto;
//       min-height: 35px;
//       height: 6vh;
//       padding: 0.5rem;
//     }

//     @media (max-width: global.$sm-break) {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }