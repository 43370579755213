@use "../global" as global;

$_modifier-xs: "-xs";
$_modifier-sm: "-sm";
$_modifier-md: "-md";
$_modifier-lg: "-lg";
$_modifier-xl: "-xl";
$_modifier-xxl: "-xxl";

.c-icon {
  display: inline-flex;
  flex-direction: column;
  // font-size: 1.75em;
  font-size: initial;
  width: 3em;
  height: 3em;

  &.#{$_modifier_xs} {
    // font-size: 1.25em;
    width: 1.6em;
    height: 1.6em;
  }

  &.#{$_modifier_sm} {
    // font-size: 1.5em;
    width: 2.5em;
    height: 2.5em;
  }

  &.#{$_modifier-lg} {
    // font-size: 2em;
    width: 4em;
    height: 4em;
  }

  &.#{$_modifier-xl} {
    // font-size: 2.5em;
    width: 5em;
    height: 5em;
  }

  &.#{$_modifier-xxl} {
    // font-size: 3em;
    width: 8rem;
    height: 8rem;
  }

  img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__items {
    position: absolute;
    margin-left: 30px;
    background: global.$primary-1;
    border-radius: global.$border-radius;
    border: 2px solid global.$white;
    width: 17px;
    text-align: center;
    font-weight: 700;
    color: global.$accent-1;
  }
}
