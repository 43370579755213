@use "../global" as global;

.sidebar {
  position: fixed;
  transition-duration: 0.4s;
  left: -100%;
  top: calc(3.4rem);
  background-color: global.$white;
  height: calc(100vh - 3.4rem + 0.8rem + 2px);
  z-index: 100;
  box-shadow: 0 0 10px global.$black;
  width: calc(global.$base * 16);

  @media (max-width: global.$sm-break) {
    width: 100%;
    height: 100vh;

    .c-dropdown-container {
      width: 100% !important;
    }
  }

  &--open {
    left: 0;
    opacity: 1;
  }

  &--closed {
    opacity: 0;
  }

  &__close-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: space-between;
    padding: global.$base;

    button {
      svg {
        fill: global.$black;

        &:hover {
          fill: global.$primary;
        }
      }
    }
  }

  &__title {
    font-size: global.$font-size-body;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  &__children {
    padding: 0 calc(global.$base * 2);
    box-sizing: border-box;
  }
}
