@use "../global" as global;

$overlay_background: rgba(0, 0, 0, 0.5);
$modal_background: global.$white;
$_border-radius: global.$border-radius;
$modal_padding: global.$xs global.$xs;

$title-font-family: global.$wolvercote;
$title-font-size: global.$sm;
$body-font-family: global.$montserrat;
$body-font-size: global.$md;
$title-color: global.$black;
$body-color: global.$black;

.c-modal {
  position: absolute;
  background-color: $modal_background;
  max-width: global.$lg-break;
  border-radius: $_border-radius;
  padding: $modal_padding;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: global.$md-break) {
    width: 80%;
  }

  &__overlay {
    align-items: center;
    background-color: $overlay_background;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    overflow-y: auto;
    z-index: 10000000;
  }

  &__title {
    font-family: $title-font-family;
    font-size: $title-font-size;
    align-items: center;
    display: flex;
    color: $title-color;
    justify-content: center;
    margin: 0 0 0.5em 0;
    font-weight: 400;
  }

  &__close {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__body {
    font-family: $body-font-family;
    font-size: $body-font-size;
    color: $body-color;
    min-height: 20vh;
    height: 100%;
    overflow-y: auto;
    max-height: 70vh;
  }

  &__footer {
    padding-top: global.$sm;
    display: flex;
    justify-content: space-evenly;
  }
}
