@use "../../global/" as global;

.npc-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0.5rem calc(global.$base * 2);
  background-color: global.$accent-1;
  border-radius: global.$border-radius;
  padding: global.$base;

  &__sort-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: global.$md-break) {
      flex-direction: column;
    }
  }

  &__container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__dropdown {
    width: 20% !important;
  }

  &__searchbar {
    width: 50%;
    margin: 0 calc(global.$base * 10);

    @media (max-width: global.$md-break) {
      width: 90%;
      margin-top: global.$base;
    }
  }

  &__add-npc {
    width: auto;
    display: flex;
    align-items: center;
    font-family: Wolvercote;

    span {
      font-size: 1.2rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    @media (max-width: global.$md-break) {
      width: calc(50% - 0.8rem) !important;
      @media (max-width: global.$sm-break) {
        width: calc(80% - 0.8rem) !important;
      }
    }

    img {
      border-radius: global.$border-radius global.$border-radius 0 0;
    }
  }

  &__mobile-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: calc(global.$base * 10);

    button {
      width: 40%;
    }
  }

  &__pagination {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
