@use "../global" as global;

$popup-color: global.$black;
$popup-font-size: global.$font-size-sm;
$popup-font-weight: bold;
$popup-font-family: global.$montserrat;
$popup-background-color: global.$white;
$popup-border-radius: global.$border-radius * 3;
$popup-padding: global.$base;
$popup-width: 40%;
$popup-left: calc((100% - $popup-width) / 2);
$popup-animation-duration: 0.65s;
$popup-translate-y: translateY(-200%);
$popup-top-offset: global.$base * 2;
$popup-top-offset-mobile: global.$base * 10;
$popup-border: 2px solid global.$black;

$popup-error-color: global.$error;
$popup-error-font-weight: global.$default-font-weight;

$popup-warning-color: orange;

$popup-slideOut-animation: slideOut $popup-animation-duration ease forwards;
$popup-slideIn-animation: slideIn $popup-animation-duration ease forwards;

$popup-md-padding: global.$base * 2;
$popup-sm-padding: global.$base * 2;
$popup-sm-md-width: 90%;
$popup-sm-md-left: calc((100% - $popup-sm-md-width) / 2) - 2%;

.c-popup {
  position: fixed;
  top: $popup-top-offset;
  background-color: $popup-background-color;
  border-radius: $popup-border-radius;
  padding: $popup-padding;
  width: $popup-width;
  z-index: 10000003;
  animation-duration: $popup-animation-duration;
  transform: $popup-translate-y;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: $popup-left;
  white-space: break-space;
  border: $popup-border;

  @media (max-width: global.$md-break) {
    padding: $popup-md-padding;
    width: $popup-sm-md-width;
    left: $popup-sm-md-left;
  }

  @media (max-width: global.$sm-break) {
    padding: $popup-sm-padding;
    width: $popup-sm-md-width;
    left: $popup-sm-md-left;
    top: $popup-top-offset-mobile;
  }

  div {
    font-family: $popup-font-family;
    font-size: $popup-font-size;
    font-weight: $popup-font-weight;
    color: $popup-color;
  }

  &__error {
    z-index: 1000005; //error message always show on top

    div {
      color: $popup-error-color;
      font-weight: $popup-error-font-weight;
    }
  }

  &__warning {
    z-index: 1000004; //then warning messages then general messages

    div {
      color: $popup-warning-color;
    }
  }

  &.slideOut {
    animation: $popup-slideOut-animation;
  }

  &.slideIn {
    animation: $popup-slideIn-animation;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: $popup-translate-y;
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: $popup-translate-y;
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
