@use "../../global/" as global;

.account {
  margin-top: 2rem;
  max-width: global.$lg-break;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__header {
    text-align: center;
    margin-bottom: calc(global.$base * 2);
    font-size: global.$font-size-h5;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__container {
    background-color: global.$white;
    width: calc(50% - global.$base * 2);
    padding: calc(global.$base * 2);
    box-sizing: border-box;
    border-radius: global.$border-radius;
    margin: global.$base;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: global.$md-break) {
      width: 100%;
    }
  }

  .c-checkbox {
    &__checkmark {
      left: 0;
      top: auto;
    }
  }

  &__title {
    margin-bottom: calc(global.$base * 2);
    font-size: global.$font-size-h6;
    text-align: center;
  }

  .MuiTextField-root {
    margin-bottom: global.$base;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__close-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .c-input {
      margin-top: calc(global.$base * 2);
    }
  }

  &__close-buttons {
    display: flex;
    justify-content: center;

    .MuiButton-root {
      margin: global.$base;
    }
  }
}
