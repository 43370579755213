@use "../../global" as global;

.fees {
  width: 100%;
  background-color: global.$white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  border-radius: global.$border-radius;
  margin-top: global.$base;
  padding: global.$base calc(global.$base * 2);

  &__container {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__pointcontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }

  &__points {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  &__stripe {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}


.support {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  &__container {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    background-color: global.$secondary-1;
    padding: global.$base * 2;
    max-width: 15rem;
    border-radius: global.$border-radius;
    margin: global.$base * 2;
    min-height: 7rem;
  }
}


.faq {
  width: 100%;
  background-color: global.$accent-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  &__container {
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: global.$base * 4;
  }

  &__col {
    width: 45%;
    padding: 0 18px;

    @media screen and (max-width: global.$md-break) {
      width: 100%;
    }
  }

  p {
    text-align: left;
  }
}