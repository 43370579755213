@use "../global" as global;


.c-thumbnail-card {
  background-color: global.$white;
  width: calc(25% - global.$base * 2);
  margin: global.$base;
  box-sizing: border-box;
  border-radius: global.$border-radius;
  color: global.$black;
  text-decoration: none;

  &:hover {
    outline: solid 1px global.$primary;
    color: global.$black;
  }

  a {
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    color: global.$black;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  &__subtitle {
    margin: global.$base;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    font-size: global.$font-size-md;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: global.$base;
    

    @media (max-width: global.$md-break) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__price {
    color: global.$primary;
    font-weight: 700;
    font-size: global.$font-size-md;
  }

  &__status {
    text-align: center;
    font-weight: 700;
  }

  &.-secondary {
    background-color: global.$black;
    color: global.$white;

    &:hover {
      outline: solid 2px global.$primary;
    }

  }
}